import PropTypes from 'prop-types';

function Link(props){
    const {title,content,users} = props;
    return (
        <div>
            <p> {JSON.stringify(users)} </p>
            <a href="https://reactjs.org/">{title}</a>
            <p>  {content} </p>
        </div>
    )
}

Link.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    users: PropTypes.array
}

Link.defaultProps = {
    users: ['testuser1']
}

export default Link;