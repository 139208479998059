import Product from './Product'

const Dashboard = () => {
    return (
        <>
            <Product/>
        </>
    )
}

export default Dashboard;