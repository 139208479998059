import React from 'react';
import './App.css';
import Link from './Link';
import User from './User';
import Comment from './useState/Comment';
import Loading from './useState/Loading';
import UserForm1 from './useState/UserForm1';
// import Users from './useState/Users';
import Theme from './useState/Theme';
import { useState } from 'react';
import User1 from './User1';
import User2 from './User2';
import Todo from './useReducer/Todo';
// import UsersDashboard from 'useContext/UsersDashboard';
import UsersDashboard from './useContext/UsersDashboard';
import FBTimeLine from './FB/FBTimeLine';
import IGTimeLine from './IG/IGTimeLine';
import Users from './useMemo/Users'; 
// --------------------------------------------
import { createBrowserRouter, createRoutesFromElements, RouterProvider ,Route } from 'react-router-dom';
import Product from './components/Product';
import Dashboard from './components/Dashboard';
import Cart from './components/Cart';
import RootLayout from './components/RootLayout';


function App() {
  // with React
  // return  React.createElement('h1',{id:'header'}, 'This is simple react element');
  
  // with Javascript
  // const appName = "React18";
  // const user = {
  //   name: 'Nisha'
  // }

  // const profileValid = false;

  // const handle = () => {
  //   console.log('Button is clicked...');
  // }

  // const users = ['Alias', 'Royal']

  // const [counter, setCounter] = useState(0);

  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={<RootLayout/>}>
      <Route index element={<Dashboard/>}/> 
      <Route path="/cart" element={<Cart/>}/>
    </Route>
  ))

  return (
    
    // <>
    //   {/* <h1 id="header" className="test">This is our simple React component: {appName}</h1> 
    //   <p>This is paragraph</p>
    //   <p>Username is {user.name}</p>
    //   <p>Is it valid profile {`${profileValid}`}</p>
    //   <p>{profileValid ? 'Valid profile': 'Invalid Profile'}</p>
    //   <button onClick={handle}>click</button> */}
    //   {/* --------------------------------------------------------------- */}

    //   {/* <div>
    //       <a href='https://reactjs.org/'>React - A Javascript library for building user interface</a>
    //       <p>
    //         Components let split the UI into independent, reusable pieces, and think about each piece in 
    //         isolation. This page provides an introduction to the idea of components.
    //       </p>
    //   </div>

    //   <div>
    //       <a href='https://reactjs.org/'>Tutorial: Intro</a>
    //       <p>
    //         Components let split the UI into independent, reusable pieces, and think about each piece in 
    //         isolation. This page provides an introduction to the idea of components.
    //       </p>
    //   </div>

    //   <div>
    //       <a href='https://reactjs.org/'>Getting Started</a>
    //       <p>   
    //         Components let split the UI into independent, reusable pieces, and think about each piece in 
    //         isolation. This page provides an introduction to the idea of components.
    //       </p>
    //   </div> */}

    //   {/* --------------------------------------------------------------- */}

    //   {/* <Link title="Tutorial: Intro" content="Content 1" users={users}/> */}
    //   {/* <Link title="Getting Started" content="Content 2" users={users}/>
    //   <Link title="Let's start" content="Content 3"  /> */}

    //   {/* <User user="Nisha"/> */}

    //   {/* --------------------------------------------------------------- */}


    //   {/* <h1 style={{textAlign: 'center'}}> useState Hook in React </h1> */}
    //   {/* <Comment/> */}


    //   {/* --------------------------------------------------------------- */}
    //   {/* <h1 style={{textAlign: 'center'}}> useState Hook with boolean type </h1> */}
    //   {/* <Loading/>       */}


    //   {/* --------------------------------------------------------------- */}
    //   {/* <h1 style={{textAlign: 'center'}}> useState Hook in React </h1> */}
    //   {/* <UserForm1/> */}

    //   {/* --------------------------------------------------------------- */}
    //   {/* <Users/> */}

    //   {/* --------------------------------------------------------------- */}
    //   {/* <Theme/> */}

    //   {/* --------------------------------------------------------------- */}
    //   {/* <div className="App">
    //     <h1>Use Effect Hook</h1>
    //     <h1>Counter {counter}</h1>
    //     <button onClick={() => setCounter(value => value + 1)}>+</button>
    //     { counter < 5 && <User1 counter={counter}/>}
    //   </div> */}

    //   {/* --------------------------------------------------------------- */}
    //   {/* <div className="App">
    //     <h1>Use Effect Hook</h1>
    //     <h1>Counter {counter}</h1>
    //     <button onClick={() => setCounter(value => value + 1)}>+</button>
    //     <User2/>
    //   </div> */}

    //   {/* --------------------------------------------------------------- */}
    //   {/* <Todo/> */}
      
    //   {/* --------------------------------------------------------------- */}
    //   {/* <UsersDashboard/> */}

    //   {/* --------------------------------------------------------------- */}
    //   {/* <h1>Interview Questions</h1>

    //   <h3>Instagram Portal</h3>
    //   <IGTimeLine.Posts title="No Posts..."/>
    //   <IGTimeLine.Reels/>      

    //   <hr/>

    //   <h3>Facebook Portal</h3>
    //   <FBTimeLine.Posts/>
    //   <FBTimeLine.Reels/> */}
      
    //   {/* --------------------------------------------------------------- */} 
    //   {/* <Users/> */}
    // </> 
    // -----------------------------------------------------------------
    // -----------------------------------------------------------------
    // -----------------------------------------------------------------
    // ------------------ REDUX TOOLKIT --------------------------------

    <div className= "App">
      
      {/* Redux Toolkit */}
      {/* <Product/> */}
      <RouterProvider router={router}/>

    </div>
    
  )
}

export default App;
